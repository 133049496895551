export default {
  baseurl: {
    dev: '127.0.0.1',
    prod: 'https://www.drapefittest.com/api'
  },
  stripe: {
    pbKey: 'pk_test_51JY90jITPrbxGSMcuo8bhxqQhCbSvHghLQaYIxtqVSe9u2xxm80SDtIVQ9acsLTW4WyPJX5G0nIMxaLXwtXbsN0N00vkBYmYDU'
  },
  bearer: {
    tokenPrefix: 'DrapeFit_New'
  },

  gcaptcha: {
    //old key:6LdcbC8qAAAAANkVnZa0u9A5nTVbWC7zIbkVYfSr
    //new key:6LcUQRArAAAAAHiZgYIvQqyr8u54Qw6708VjjcWL
    siteKey: '6LfsqxMrAAAAAI63xEmzw3B8-saXJinxUn2Bl828'
  },
  facebook_apId: {
    appid: '4074924512731146',
    social_success_redirect: 'https://www.drapefittest.com/users/fbreturn',
    state: 'your_unique_state_value'
  },

  google_clientId: {
    clientId: '576534915351-6hhl4sgtqfqjdb2lul4vocgk9flghe80.apps.googleusercontent.com'
  },
  aws: {
    region: 'ap-south-1',
    s3Bucket: 'new-drapefit-bucket'
  }
};
