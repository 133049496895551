import { setAlert } from 'actions/common/alert';
import {
  SET_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  VERIFY_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  GET_EMAIL,
  GET_NEWS,
  USER_PROFILE
} from 'actions/common/types';
import Api from 'utils/Api';
import { ErrorHandler } from 'utils/ErrorHandler';
import DFnewLogger from 'utils/DFnewLogger';

export const loadUser = (navigate) => async (dispatch) => {
  try {
    const res = await Api.get('/user/uinfo');
    console.log(res.data);

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

    if (navigate) {
      //localStorage.setItem('fitFor', res.data?.fitFor);
      const fitFor = Number(localStorage.getItem('fitFor'));
      const order = Number(localStorage.getItem('order')); // Assuming you need `order` from localStorage as well
      if (!fitFor) {
        localStorage.setItem('fitFor', res.data?.fitFor);
      }
      setTimeout(() => {
        if (fitFor < 3) {
          navigate(res.data.pRoute);
        } else {
          // Check if kids data is available and navigate to kRoute
          const kidData = res.data.kids?.find((kid) => kid.order === order);
          if (kidData) {
            navigate(kidData.kRoute);
          } else {
            // Fallback to default route if no kid data matches
            navigate('/some-default-route');
          }
        }
      }, 0);
    }
  } catch (err) {
    console.error('Error loading user:', err.message);
    dispatch({ type: AUTH_ERROR });
  }
};

export const signup = async (values, navigate) => {
  try {
    const res = await Api.post('/user/signup', values);
    if (res.data === 'Please verify email sent to your account') {
      navigate('/verify');
      setAlert(res.data, 'success');
    } else if (res.data === 'Please login to your account') {
      navigate('/login');
      setAlert(res.data, 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const checkEmailAvailability = async (values) => {
  try {
    const res = await Api.post('/user/check', values);
    console.log('responf:', res);
    return res.data.exists;
  } catch (err) {
    console.error(err);
    throw new Error('API request failed');
  }
};
export const verify = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/user/verify', formData);
    dispatch({
      type: VERIFY_SUCCESS,
      payload: res.data
    });
    dispatch(loadUser(navigate));
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const login = (values, isRememberMe, navigate) => async (dispatch) => {
  try {
    DFnewLogger(values);
    dispatch({ type: SET_LOADING });
    const res = await Api.post('/user/login', values);
    if (res.data) {
      dispatch({ type: SET_LOADING });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
        isRememberMe
      });
      dispatch(loadUser(navigate));
      setAlert(`Welcome, ${res.data?.name}`, 'info');
    } else {
      setAlert('ACTION_login_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const loginGoogle = (email, isRememberMe, navigate) => async (dispatch) => {
  try {
    DFnewLogger(email); // Log email or any other detail.
    //dispatch({ type: SET_LOADING });

    // Make the API call to login with Google.
    const res = await Api.post('/user/loginGoogle', { email });
    if (res.data) {
      // On successful login, dispatch actions and navigate
      dispatch({ type: LOGIN_SUCCESS, payload: res.data, isRememberMe });
      dispatch(loadUser(navigate));
      setAlert(`Welcome, ${res.data?.name}`, 'info');
    } else {
      setAlert('ACTION_login_ERROR', 'error');
    }
  } catch (err) {
    // Handle the error here and stop loading
    dispatch({ type: SET_LOADING });
    DFnewLogger(err.message); // Log the error for debugging.
    setAlert('Login failed', 'error');
    ErrorHandler(err); // This should handle the error and display a message.
  }
};

export const loginAdmin = (values, isRememberMe, navigate) => async (dispatch) => {
  try {
    DFnewLogger(values);
    dispatch({ type: SET_LOADING });
    const res = await Api.post('/user/login/admin', values);
    dispatch({ type: SET_LOADING });
    if (res.data) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
        isRememberMe
      });
      dispatch(loadUser(navigate));
      //-- Welcome message
      setAlert(`Welcome, ${res.data?.name}`, 'info');
    } else {
      setAlert('ACTION_loginAdmin_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const loginInventory = (values, isRememberMe, navigate) => async (dispatch) => {
  try {
    DFnewLogger(values);
    dispatch({ type: SET_LOADING });
    const res = await Api.post('/user/login/inventory', values);
    dispatch({ type: SET_LOADING });
    if (res.data) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
        isRememberMe
      });
      dispatch(loadUser(navigate));
      //-- Welcome message
      setAlert(`Welcome, ${res.data?.name}`, 'info');
    } else {
      setAlert('ACTION_loginInventory_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const loginSupplier = (values, isRememberMe, navigate) => async (dispatch) => {
  try {
    DFnewLogger(values);
    dispatch({ type: SET_LOADING });
    const res = await Api.post('/user/login/supplier', values);
    dispatch({ type: SET_LOADING });
    if (res.data) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
        isRememberMe
      });
      dispatch(loadUser(navigate));
      //-- Welcome message
      setAlert(`Welcome, ${res.data?.name}`, 'info');
    } else {
      setAlert('ACTION_loginSupplier_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const forgotPwd = async (formData, navigate) => {
  try {
    const res = await Api.post('/user/forgotpwd', formData);
    if (res.data === 'Message with reset token sent to that email') {
      navigate('/reset-pwd');
      setAlert(res.data, 'success');
    } else {
      navigate('/reset-pwd');
      setAlert('Reset token has been created', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const resetPwd = async (formData, navigate) => {
  try {
    const res = await Api.post('/user/resetpwd', formData);
    setAlert(res.data, 'success');
    navigate('/login');
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const editLoginDetails = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/setting/logindetails/edit', data);
    if ((res.data = 'Login details have been updated')) {
      dispatch(loadUser());
      setAlert('Login details have been updated', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await Api.post('/user/logout');
    dispatch({ type: SET_LOADING });
    if (res.data === 'User logged out') {
      dispatch({ type: LOGOUT });
    } else {
      setAlert('ACTION_logout_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const updateProfile = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/user/profile', data);
    if (res.data) {
      dispatch(loadUser());
      setAlert('Success', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const getEmails = () => async (dispatch) => {
  try {
    const res = await Api.get('/user/getEmail');
    if (res.data) {
      dispatch({
        type: GET_EMAIL,
        payload: res.data
      });
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const updatePassword = (data) => async () => {
  try {
    const res = await Api.post('/user/updatePassword', data);
    if (res.data) {
      setAlert(res.data.msg, res.data.type);
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const getProfile = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/user/getProfile', data);
    if (res.data) {
      dispatch({
        type: USER_PROFILE,
        payload: res.data
      });
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const contactus = async (values, navigate) => {
  try {
    const res = await Api.post('/user/contactus', values);
    console.log('response:', res);
    if (res.data.msg === 'Thank you, We will get back to you soon.') {
      setAlert(res.data.msg, 'success');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.data === 'Please login to your account') {
      setAlert(res.data, 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const career = async (values, navigate) => {
  try {
    console.log('value carreer:', values);
    const res = await Api.post('/user/career', values);
    console.log('response:', res);
    if (res.data.msg === 'Thank you, We will get back to you soon.') {
      setAlert(res.data, 'success');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (res.data === 'Please login to your account') {
      setAlert(res.data, 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const getNews = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await Api.get('/user/getNews');
    dispatch({ type: SET_LOADING });
    if (res.data) {
      dispatch({
        type: GET_NEWS,
        payload: res.data
      });
    } else {
      setAlert('ACTION_getNews_ERROR', 'error');
    }
  } catch (err) {
    dispatch({ type: SET_LOADING });
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const inviteFriend = async (values, navigate) => {
  try {
    const res = await Api.post('/user/invite', values);
    console.log('response:', res);
    if (res.data) {
      // Redirect based on 'sod'
      const sod = res.data.sod;
      if (sod === 'w') {
        navigate('/invite/women');
      } else if (sod === 'm') {
        navigate('/invite/men');
      } else if (sod === 'k') {
        navigate('/invite/kids');
      } else {
        navigate('/');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
